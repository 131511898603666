<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
const { data: customerService } = await useBlockText('customer-service')
</script>

<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.loginResetPasswordCustomerServiceSide"
    is-alpha-layer-transparent
    :header-props="{
      titleText: customerService?.content?.title
        ? customerService.content.title
        : $ts('header.customerService'),
      hasBackButton: true,
    }"
  >
    <template #body>
      <UtilsMarkdown
        v-if="customerService?.content?.text"
        :content="customerService.content.text"
        class="text-book-6 [&>h2]:text-medium-6"
      />
    </template>
  </OrganismsSidebarSlide>
</template>
