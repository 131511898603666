export const useResetPassword = () => {
  const emailSent = ref(false)

  const sendEmail = async (email: string, currentDomain: string) => {
    try {
      await $fetch('/api/auth0/requestPasswordChange', {
        method: 'POST',
        body: {
          email,
          currentDomain,
        },
      })
      emailSent.value = true
    } catch (error) {
      console.error(error)
    }
  }

  return {
    emailSent,
    sendEmail,
  }
}
